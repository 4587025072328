@import './simplifi-design-compiled.scss';
@import './variables.scss';

// Override elements here

@font-face {
  font-family: element-icons;
  src:
    url(../../assets/icons/element-icons.woff) format('woff'),
    url(../../assets/icons/element-icons.ttf) format('truetype');
  font-weight: 400;
  font-display: 'auto';
  font-style: normal;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.max-w-400 {
  max-width: 400px;
}
.min-w-180 {
  min-width: 180px;
}
.is-middle {
  align-items: center !important;
}
.el-form--label-top {
  .el-form-item {
    margin-bottom: 16px !important;
  }
}
.el-form-item__content {
  position: static !important;
}
.warning-color {
  color: $warn-color;
}
.danger-color {
  color: #ca2525;
}
.success-color {
  color: $success-color;
}
.d-none {
  display: none !important;
}
.dark-blue-bg {
  background-color: $dark-blue;
}
.dark-green-bg {
  background-color: $dark-green;
}
.cyan-bg {
  background-color: $cyan;
}
.dark-grey-bg {
  background-color: #cecece;
}
.red-bg {
  background-color: #ca2525;
}
.orange-bg {
  background-color: #ff9d00;
}
.color-primary-blue {
  color: #2f6887;
}
.font-xs {
  font-size: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
.el-form--label-top {
  .el-form-item__label {
    padding-bottom: 3px !important;
    vertical-align: top !important;
    line-height: normal;
  }
}
.simpli-button-group {
  .mat-button-toggle {
    background-color: $list-border-color;
    color: $text-primary;
    border: 0 none;
    &.mat-button-toggle-checked {
      background-color: $color-primary;
      color: white;
    }
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        height: 32px;
        padding: 6px 7px;
        line-height: normal;
        .mat-pseudo-checkbox {
          display: none;
        }
      }
    }
  }
}

.max-w-320 {
  max-width: 320px;
}
.strong {
  font-weight: bold;
}
.font-lg {
  font-size: 1.25em;
}
