@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5wteyw');
  src:  url('fonts/icomoon.eot?5wteyw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5wteyw') format('truetype'),
    url('fonts/icomoon.woff?5wteyw') format('woff'),
    url('fonts/icomoon.svg?5wteyw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-no-rows:before {
  content: "\e916";
}
.icon-addressable:before {
  content: "\e90a";
}
.icon-geofence:before {
  content: "\e90b";
}
.icon-site-retargetting:before {
  content: "\e90c";
  color: #2f6887;
}
.icon-ztv .path1:before {
  content: "\e90d";
  color: rgb(30, 30, 30);
}
.icon-ztv .path2:before {
  content: "\e90e";
  margin-left: -1.0771484375em;
  color: rgb(64, 64, 64);
  opacity: 0.6;
}
.icon-ztv .path3:before {
  content: "\e90f";
  margin-left: -1.0771484375em;
  color: rgb(255, 255, 255);
  opacity: 0.1;
}
.icon-ztv .path4:before {
  content: "\e910";
  margin-left: -1.0771484375em;
  color: rgb(64, 64, 64);
}
.icon-ztv .path5:before {
  content: "\e911";
  margin-left: -1.0771484375em;
  color: rgb(255, 255, 255);
  opacity: 0.1;
}
.icon-ztv .path6:before {
  content: "\e912";
  margin-left: -1.0771484375em;
  color: rgb(255, 255, 255);
}
.icon-ztv .path7:before {
  content: "\e913";
  margin-left: -1.0771484375em;
  color: rgb(25, 25, 25);
}
.icon-ztv .path8:before {
  content: "\e914";
  margin-left: -1.0771484375em;
  color: rgb(25, 25, 25);
}
.icon-search:before {
  content: "\e915";
}
.icon-Status-Complete .path1:before {
  content: "\e900";
  color: rgb(30, 227, 12);
}
.icon-Status-Complete .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(25, 25, 25);
}
.icon-Status-Downloaded .path1:before {
  content: "\e902";
  color: rgb(19, 148, 8);
}
.icon-Status-Downloaded .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Status-Draft:before {
  content: "\e904";
}
.icon-Status-Error:before {
  content: "\e905";
  color: #fff;
}
.icon-Status-Expired .path1:before {
  content: "\e906";
  color: rgb(25, 25, 25);
}
.icon-Status-Expired .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Status-Pending:before {
  content: "\e908";
  color: #fff;
}
.icon-Status-Processing:before {
  content: "\e909";
  color: #fff;
}
