// new scss variables here
$simpliFont: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$color-primary: #2f6887;
$body-bg-color: #efefef;
$text-primary: #191919;
$text-secondary: #505050;
$lt-border-color: #b6b6b6;
$list-border-color: #cecece;
$login-bg: $text-primary;
$scroll-color: #d1d1d1;
$scroll-hover-color: #000000;
$header-height: 70px;
$notification-color: #2f6887;
$warn-color: #fc710d;
$simpli-green: #1ee30c;
$brand-secondary: #c73e1d;
$draft-color: #9ec7dd;
$color-grey-50: #858585;
$success-color: #0f7406;
$dark-blue: #754ad0;
$dark-green: #099b7f;
$cyan: #00d1ff;
$interaction-info: #6dabcc;
$tile-bg: #f3f3f3;
$purple-3: #4d3189;
$violet-2: #cc00ff;
$table-bg: #f9f9f9;
$danger-color: #ca2525;
$filled-checkbox: #2d6380;
